var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "wpcm-row wpcm-justify-content-center" }, [
      _c(
        "div",
        { staticClass: "wpcm-col-sm-12" },
        [
          _vm._t("donation_dropdowns"),
          _vm._v(" "),
          _vm.show_currency_dropdown && _vm.currencies
            ? _c(
                "div",
                { staticClass: "el-custom-select mt-5" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-100",
                      attrs: {
                        value: _vm.currency,
                        placeholder: "Select Currency"
                      },
                      on: {
                        change: function($event) {
                          return _vm.setValue("currency", $event)
                        }
                      }
                    },
                    _vm._l(_vm.currencies, function(curr, key) {
                      return _c(
                        "el-option",
                        { key: key, attrs: { value: key, label: curr } },
                        [_vm._v(_vm._s(curr))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "donation-amount-box my-5" }, [
            _c("h4", [_vm._v("How much would you like to donate?")]),
            _vm._v(" "),
            _vm.custom_amount
              ? _c(
                  "div",
                  { staticClass: "custom-donation-amount wpcm-d-inline-block" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.getSymbol()))]),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "text" },
                      domProps: { value: _vm.amount },
                      on: { input: _vm.setAmount }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "donation-amount-list" }, [
              _vm.show_amounts && _vm.amounts
                ? _c(
                    "ul",
                    { staticClass: "list-unstyled" },
                    _vm._l(_vm.amounts, function(amnt) {
                      return _c(
                        "li",
                        {
                          key: amnt,
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setValue("amount", amnt)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              class: [
                                "wpdonation-button",
                                _vm.amount == amnt && "active"
                              ],
                              attrs: { href: "#" }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getSymbol() + " " + amnt)
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }