var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.top_title
      ? _c("h5", { staticClass: "wpcm-top-title" }, [
          _vm._v(_vm._s(_vm.top_title))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.title
      ? _c("h3", { staticClass: "wpcm-popup-title" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.tagline
      ? _c("p", { staticClass: "wpcm-popup-tagline" }, [
          _vm._v(_vm._s(_vm.tagline))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }