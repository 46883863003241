<template>
  <div class="text-center mt-4" v-if="post_id <= 0">
    <div class="form-group">
      <label for="donation-purpose" class="mb-4 font-weight-bold" v-if="strings">{{
        strings.title
      }}</label>

      <div class="mb-4">
        <el-radio
          v-model="donation_purpose"
          @change="getData()"
          label="all_projects_causes"
          border
          v-if="postExists('project') && postExists('cause')"
          >{{ strings ? strings.all_projects_causes : 'All Projects & Charities' }}</el-radio
        >
        <el-radio
          v-model="donation_purpose"
          v-if="postExists('project')"
          @change="getData()"
          label="all_projects"
          border
          >{{ strings ? strings.all_projects : 'All Projects' }}</el-radio
        >
        <el-radio
          v-model="donation_purpose"
          v-if="postExists('cause')"
          @change="getData()"
          label="all_causes"
          border
          >{{ strings ? strings.all_causes : 'All Causes' }}</el-radio
        >

        <slot name="donation_options"></slot>

        <el-radio v-model="donation_purpose" label="custom" border
          >{{ strings ? strings.own_selection : 'My Own Selection' }}</el-radio
        >
      </div>

      <div class="mb-4 el-custom-select">
        <el-select
          v-model="custom_donation_purpose"
          v-if="donation_purpose == 'custom'"
          filterable
          @change="getData()"
          :placeholder="strings ? strings.custom_donation_purpose : 'Choose Custom Donation Options'"
          multiple
          :style="{ width: '66%' }"
        >
          <el-option-group
            :label="strings ? strings.projects : 'Projects'"
            v-if="projects"
          >
            <el-option
              v-for="(project, ID) in projects"
              :key="ID"
              :value="ID"
              :label="project"
            >{{ project }}</el-option>
          </el-option-group>

          <el-option-group
            :label="strings ? strings.charities : 'Charities'"
          >
            <el-option
              v-for="(cause, ID) in causes"
              :key="ID"
              :value="ID"
              :label="cause"
            >{{ cause }}</el-option>
          </el-option-group>
          <slot name="other_donation_options"></slot>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
  props: ["post_types", "projects", "causes", "strings"],
  data() {
    return {
      donation_purpose: '',
      custom_donation_purpose: ''
    }
  },
  computed: {
    ...mapState(['post_id']),
  },
  watch: {
    donation_purpose(val) {
      this.$store.commit('setExtras', {key: 'donation_purpose', val})
    },
    custom_donation_purpose(val) {
      this.$store.commit('setExtras', {key: 'custom_donation_purpose', val})
    },
  },
  methods: {
    postExists(post) {
      let found = _.filter(this.post_types, (value) => value == post);
      return found;
    },
    setExtras(key, val) {
      this.$store.commit('setExtras', {key, val})
    },
    getData() {
      this.$store.dispatch("getData", { vm: this });
    }
  },
};
</script>