<template>
	<div class="">
    <div class="wpcm-radial-progress-bar" v-show="show_progress">
      <div class="circular" v-if="collected >= 0 && needed">
        <input
          class="knob"
          ref="knob"
          data-fgColor="#d8281b"
          data-bgColor="#dddddd"
          data-thickness=".10"
          readonly
          :value="((collected / needed)*100).toFixed(1)"
        /><span v-if="strings">{{ strings.completed }}</span>
      </div>
    </div>
    <div v-if="show_collection" class="wpcm-amount-collected">
      <span v-if="currency_position == 'left'" class="amount-return"
        ><i>{{ symbol }}</i>{{ collected }}</span
      >
      <span v-else-if="currency_position == 'right'" class="amount-return"
        >{{ collected }}<i>{{ symbol }}</i></span
      >
      <span v-else-if="currency_position == 'left_s'" class="amount-return"
        ><i>{{ symbol }}</i> {{ collected }}</span
      >
      <span v-else-if="currency_position == 'right_s'" class="amount-return"
        >{{ collected }} <i>{{ symbol }}</i></span
      >
      <span v-else class="amount-return"
        ><i>{{ symbol }}</i>{{ collected }}</span
      ><span v-if="strings">{{ strings.collection }}</span>
    </div>
    <div class="wpcm-amount-needed" v-if="show_collection">
    	<span v-if="currency_position == 'left'" class="amount-return"
    	  ><i>{{ symbol }}</i>{{ needed }}</span
    	>
    	<span v-else-if="currency_position == 'right'" class="amount-return"
    	  >{{ needed }}<i>{{ symbol }}</i></span
    	>
    	<span v-else-if="currency_position == 'left_s'" class="amount-return"
    	  ><i>{{ symbol }}</i> {{ needed }}</span
    	>
    	<span v-else-if="currency_position == 'right_s'" class="amount-return"
    	  >{{ needed }} <i>{{ symbol }}</i></span
    	>
    	<span v-else class="amount-return"
    	  ><i>{{ symbol }}</i>{{ needed }}</span
    	>
      <span v-if="strings">{{ strings.target }}</span>
    </div>
    
	</div>
</template>

<script>
const $ = jQuery;
export default {
	props: [
		"strings",
		"show_title",
		"top_title",
		"title",
		"tagline",
		"collected",
		"needed",
		"symbol",
		"show_progress",
		"show_collection",
		"currency_position"
	],
	mounted() {
    if(this.show_progress) {
      this.knob();
    }
	},
	methods: {
		knob() {
			if ($.fn.knob !== undefined) {
				$(this.$refs.knob).knob();
			}
		}
	}
};
</script>
