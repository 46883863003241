var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "margin-top": "-80px" } }, [
    _vm.show_collection
      ? _c("div", { staticClass: "donation-amount-bar" }, [
          _c("div", { staticClass: "amount-info-box" }, [
            _c("h2", [
              _c("span", [_vm._v(_vm._s(_vm.symbol))]),
              _vm._v(_vm._s(_vm.needed))
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.strings ? _vm.strings.target : "Target Neeeded")
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "amount-info-box" }, [
            _c("h2", [
              _c("span", [_vm._v(_vm._s(_vm.symbol))]),
              _vm._v(_vm._s(_vm.collected))
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.strings ? _vm.strings.collection : "Collected"))
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "donation-box-title" }, [
      _c("span", [_vm._v(_vm._s(_vm.tagline))]),
      _vm._v(" "),
      _c("h2", [_vm._v(_vm._s(_vm.title))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }