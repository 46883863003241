var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.post_id <= 0
    ? _c("div", { staticClass: "text-center mt-4" }, [
        _c("div", { staticClass: "form-group" }, [
          _vm.strings
            ? _c(
                "label",
                {
                  staticClass: "mb-4 font-weight-bold",
                  attrs: { for: "donation-purpose" }
                },
                [_vm._v(_vm._s(_vm.strings.title))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _vm.postExists("project") && _vm.postExists("cause")
                ? _c(
                    "el-radio",
                    {
                      attrs: { label: "all_projects_causes", border: "" },
                      on: {
                        change: function($event) {
                          return _vm.getData()
                        }
                      },
                      model: {
                        value: _vm.donation_purpose,
                        callback: function($$v) {
                          _vm.donation_purpose = $$v
                        },
                        expression: "donation_purpose"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.strings
                            ? _vm.strings.all_projects_causes
                            : "All Projects & Charities"
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.postExists("project")
                ? _c(
                    "el-radio",
                    {
                      attrs: { label: "all_projects", border: "" },
                      on: {
                        change: function($event) {
                          return _vm.getData()
                        }
                      },
                      model: {
                        value: _vm.donation_purpose,
                        callback: function($$v) {
                          _vm.donation_purpose = $$v
                        },
                        expression: "donation_purpose"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.strings
                            ? _vm.strings.all_projects
                            : "All Projects"
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.postExists("cause")
                ? _c(
                    "el-radio",
                    {
                      attrs: { label: "all_causes", border: "" },
                      on: {
                        change: function($event) {
                          return _vm.getData()
                        }
                      },
                      model: {
                        value: _vm.donation_purpose,
                        callback: function($$v) {
                          _vm.donation_purpose = $$v
                        },
                        expression: "donation_purpose"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.strings ? _vm.strings.all_causes : "All Causes"
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("donation_options"),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "custom", border: "" },
                  model: {
                    value: _vm.donation_purpose,
                    callback: function($$v) {
                      _vm.donation_purpose = $$v
                    },
                    expression: "donation_purpose"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.strings
                        ? _vm.strings.own_selection
                        : "My Own Selection"
                    )
                  )
                ]
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-4 el-custom-select" },
            [
              _vm.donation_purpose == "custom"
                ? _c(
                    "el-select",
                    {
                      style: { width: "66%" },
                      attrs: {
                        filterable: "",
                        placeholder: _vm.strings
                          ? _vm.strings.custom_donation_purpose
                          : "Choose Custom Donation Options",
                        multiple: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.getData()
                        }
                      },
                      model: {
                        value: _vm.custom_donation_purpose,
                        callback: function($$v) {
                          _vm.custom_donation_purpose = $$v
                        },
                        expression: "custom_donation_purpose"
                      }
                    },
                    [
                      _vm.projects
                        ? _c(
                            "el-option-group",
                            {
                              attrs: {
                                label: _vm.strings
                                  ? _vm.strings.projects
                                  : "Projects"
                              }
                            },
                            _vm._l(_vm.projects, function(project, ID) {
                              return _c(
                                "el-option",
                                {
                                  key: ID,
                                  attrs: { value: ID, label: project }
                                },
                                [_vm._v(_vm._s(project))]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-option-group",
                        {
                          attrs: {
                            label: _vm.strings
                              ? _vm.strings.charities
                              : "Charities"
                          }
                        },
                        _vm._l(_vm.causes, function(cause, ID) {
                          return _c(
                            "el-option",
                            { key: ID, attrs: { value: ID, label: cause } },
                            [_vm._v(_vm._s(cause))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm._t("other_donation_options")
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }