var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "span",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.showModal = true
            }
          }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c("lifeline-donation-modal", {
            attrs: { showModal: _vm.showModal, id: _vm.id, dstyle: _vm.dstyle },
            on: {
              close: function($event) {
                _vm.showModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }