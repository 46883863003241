var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.step === _vm.config.steps
        ? _c(
            "el-button",
            {
              class: [_vm.config.proceed_classes || "wpcm-proceed-btn"],
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v(_vm._s(_vm.config.donate_now))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }