<template>
  <div>
    <el-button
      :class="[config.proceed_classes || 'wpcm-proceed-btn']"
      @click="submit()"
      v-if="step === config.steps"
      >{{ config.donate_now }}</el-button
    >
  </div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
import descriptor from '../utils/rules'
export default {
  props: [],
  computed: {
    ...mapState(["loading", "components", "config", "step"]),
  },
  methods: {
    ...mapMutations(["next", "back", "reset"]),
    submit() {
      const { state } = this.$store;
      this.$store
        .dispatch("validate", { rules: descriptor, fields: state })
        .then((res) => {
          this.$store.dispatch('submit', {vm: this})
        })
        .catch((fields, errors) => {
          this.validation_catch(fields, errors);
        });
      // this.$store.dispatch("submit", { vm: this });
    },
    validation_catch(fields, errors) {
      _.each(fields, (field) => {
        setTimeout(() => {
          this.$notify.error({
            title: "Error",
            message: field[0].message,
            offset: 40,
          });
        }, 300);
      });
    },
  }
}
</script>