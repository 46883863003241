<template>
  <el-button @click="$store.commit('next')" v-if="step < config.steps ">
    {{ text || 'Proceed' }} 
  </el-button>
</template>

<script>
const { mapState } = window.Vuex;
export default {
  props: ['text'],
  computed: {
    ...mapState(['step', 'config'])
  }
}
</script>