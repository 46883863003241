<template>
  <ul class="wpcm-pre-dfind-amt" v-if="amounts">
    <li
      v-for="(amnt) in amounts"
      :key="amnt"
      @click.prevent="setValue('amount', convert(amnt))"
    >
      <a
        href="#"
        :class="[
          'wpdonation-button',
          amount == convert(amnt) && 'active'
        ]"
      >
        {{ formatPrice(amnt) }}</a
      >
    </li>
  </ul>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
  props: ['amounts', 'symbols', 'symbol', 'format_price'],
  computed: {
    ...mapState(["amount", "currency", "recurring"]),
  },
  mounted() {
    if(! this.amount && _.size(this.amounts)) {
      this.setValue('amount', this.amounts[0])
    }
  },
  methods: {
    ...mapMutations(["setAmount"]),
    setValue(key, val) {
      this.$store.commit("setValue", { key, val });
    },
    getSymbol() {
      return (this.symbols[this.currency] != undefined) ? this.symbols[this.currency] : this.symbol
    },
    convert(amt) {
      const { exchange_rates } = window.lifeline_donation
      const syb = this.getSymbol()
      console.log(_.get(exchange_rates, syb))
      if(_.get(exchange_rates, syb)) {
        return parseFloat(_.get(exchange_rates, syb) * amt);
      }

      return amt
    },
    formatPrice(price) {
      let settings = this.format_price;
      let sym = this.getSymbol();
      let d_point = settings.d_point;
      let d_sep = settings.d_sep;
      let position = settings.position;
      let sep = settings.sep;

      price = this.formatMoney(price, d_point, d_sep, sep);
      if (position == "left") {
        price = sym + price;
      } else if (position == "right") {
        price = price + sym;
      } else if (position == "left_s") {
        price = sym + " " + price;
      } else if (position == "right_s") {
        price = price + " " + sym;
      } else {
        price = sym + " " + price;
      }
      return price;
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {
        this.$notify({ type: "error", message: e });
      }
    }
  }
}
</script>