<template>
  <div>
    <div class="wpcm-custm-amt-before-title">
      <slot name="before_title"></slot>
      <h3 v-if="title" class="wpcm-custm-amt-title">{{ title }}</h3>
    </div>
    <div class="wpcm-custom-amt-box-container">
      <slot name="before_box"></slot>
      <div class="wpcm-custm-amt-box" v-if="custom_amount">
        <span v-if="symbol" class="wpcm-symbl-prefix">{{ getSymbol() }}</span>
        <input 
          :value="amount"
          @input="setAmount"
          @keypress="isNumber($event)"
          :placeholder="
            strings
              ? strings.donation_amount
              : 'Enter The Amount You Want'
          "
        />
        <slot name="in_box"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
const { mapState, mapMutations } = window.Vuex;
export default {
  props: ['custom_amount', 'title', 'symbol', 'symbols', 'strings'],
  computed: {
    ...mapState(["amount", "currency", "recurring"]),
  },
  methods: {
    ...mapMutations(["setAmount"]),
    getSymbol() {
      return (this.symbols[this.currency] != undefined) ? this.symbols[this.currency] : this.symbol
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    }
  }
}
</script>