const descriptor = {

    amount: {
        type: "number",
        required: true,
        message: "Amount is require and must be a valid number",
        validator: (rule, value) => value > 0,
        transform(value) {
            return parseFloat(value)
        },
    },
    payment_method: {
        type: "string",
        required: true,
        message: "Please select the payment method",
    },
    reucrring: { type: 'enum', enum: [true, false] },
    billing_fields: {
        type: 'object',
        required: true,
        fields: {
            first_name: { type: "string", required: true, message: 'First name is required' },
            last_name: { type: "string", required: true, message: 'Last name is required' },
            // phone: { type: "string", required: true, message: 'Phone is required and must be a valid phone number' },
            address: { type: "string", required: true, message: 'Addres is required' },
            email: { type: "email", required: true, message: 'Email is required and must be a valid email' },
        }
    },

};

export default descriptor;