var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "wpcm-recurring-btns" }, [
      _vm.show_recurring
        ? _c("ul", { staticClass: "d-inline-flex" }, [
            _vm.strings
              ? _c(
                  "li",
                  {
                    staticClass: "m-0",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setValue("recurring", true)
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        class: _vm.recurring && "active",
                        attrs: { href: "#" }
                      },
                      [_vm._v(_vm._s(_vm.strings.recurring))]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.strings
              ? _c(
                  "li",
                  {
                    staticClass: "m-0",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setValue("recurring", false)
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        class: !_vm.recurring && "active",
                        attrs: { href: "#" }
                      },
                      [_vm._v(_vm._s(_vm.strings.one_time))]
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wpcm-payment-gateways mt-3" },
      [
        _c("div", { staticClass: "mb-3" }, [
          _vm.gateways
            ? _c(
                "ul",
                { staticClass: "wpcm-gateway-list" },
                _vm._l(_vm.gateways, function(gateway, gateway_id) {
                  return _c("li", { key: gateway_id }, [
                    _c(
                      "a",
                      {
                        class: [gateway.id === _vm.payment_method && "active"],
                        attrs: { title: "", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.setValue("payment_method", gateway.id)
                          }
                        }
                      },
                      [
                        gateway.icon
                          ? _c("span", [
                              _c("img", {
                                attrs: { src: gateway.icon, alt: "" }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\r\n            " +
                            _vm._s(gateway.title ? gateway.title : gateway.name)
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._t("gateway_data"),
        _vm._v(" "),
        _vm._t("billing_info"),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }