var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "wpcm-wrapper",
      attrs: {
        visible: _vm.showModal,
        width: _vm.config.width,
        "custom-class":
          "donation-style-" +
          (_vm.dstyle || 1) +
          " " +
          _vm.config.wrapper_class,
        "modal-append-to-body": true,
        "append-to-body": true,
        "show-close": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showModal = $event
        },
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "closep",
          attrs: { slot: "title" },
          on: {
            click: function($event) {
              _vm.showModal = false
            }
          },
          slot: "title"
        },
        [_c("i", { staticClass: "el-icon-close" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "dialog-content"
        },
        [
          _vm.components
            ? _c(
                "modal-template",
                { attrs: { config: _vm.config, currentStep: _vm.step } },
                [
                  _vm._l(_vm.components, function(comp, index) {
                    return [
                      _c("modal-view", {
                        key: index,
                        attrs: { slot: comp.slot, comp: comp },
                        slot: comp.slot
                      })
                    ]
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt-4 text-center single-proced-btn",
              staticStyle: { display: "none" }
            },
            [
              _vm.step < _vm.config.steps
                ? _c(
                    "el-button",
                    {
                      class: [_vm.config.proceed_classes || "proceed btn"],
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.config.proceed && "Proceed"))]
                  )
                : _c(
                    "el-button",
                    {
                      class: [_vm.config.proceed_classes || "proceed btn"],
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.config.donate_now))]
                  ),
              _vm._v(" "),
              _vm.step > 1 && _vm.config.show_back_btn
                ? _c(
                    "el-button",
                    {
                      class: [_vm.config.proceed_classes || "proceed btn"],
                      on: {
                        click: function($event) {
                          return _vm.back()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.config.back_text && "Go Back"))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }