<template>
  <div style="margin-top: -80px;">
    <div class="donation-amount-bar" v-if="show_collection">
      <div class="amount-info-box">
        <h2><span>{{ symbol }}</span>{{ needed }}</h2>
        <span>{{ strings ? strings.target : 'Target Neeeded' }}</span>
      </div>
      <div class="amount-info-box">
        <h2><span>{{ symbol }}</span>{{ collected }}</h2>
        <span>{{ strings ? strings.collection : 'Collected' }}</span>
      </div>
    </div>
    <div class="donation-box-title">
      <span>{{ tagline }}</span>
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "strings",
		"show_title",
		"top_title",
		"title",
		"tagline",
		"collected",
		"needed",
		"symbol",
		"show_progress",
		"show_collection"
  ]
}
</script>