var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "step2" }, [
    _c("div", { staticClass: "donar-info" }, [
      _c("h4", [_vm._v("Personal Info")]),
      _vm._v(" "),
      _c("form", [
        _c("input", {
          attrs: {
            type: "text",
            placeholder: _vm.trans("first_name"),
            disabled: _vm.loading,
            required: ""
          },
          domProps: { value: _vm.billing_fields.first_name },
          on: {
            input: function($event) {
              return _vm.setBillingValue("first_name", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "text",
            placeholder: _vm.trans("last_name"),
            disabled: _vm.loading,
            required: ""
          },
          domProps: { value: _vm.billing_fields.last_name },
          on: {
            input: function($event) {
              return _vm.setBillingValue("last_name", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "email",
            placeholder: _vm.trans("email"),
            readonly: _vm.is_logged_in && _vm.email,
            disabled: _vm.loading,
            required: ""
          },
          domProps: { value: _vm.billing_fields.email },
          on: {
            input: function($event) {
              return _vm.setBillingValue("email", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "tel",
            placeholder: _vm.trans("phone"),
            disabled: _vm.loading,
            onkeyup: "this.value=this.value.replace(/[^\\d]/,'')"
          },
          domProps: { value: _vm.billing_fields.phone },
          on: {
            input: function($event) {
              return _vm.setBillingValue("phone", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "text",
            placeholder: _vm.trans("address"),
            disabled: _vm.loading
          },
          domProps: { value: _vm.billing_fields.address },
          on: {
            input: function($event) {
              return _vm.setBillingValue("address", $event.target.value)
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }