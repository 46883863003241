<template>
  <component
    v-if="comp"
    :is="comp.is"
    v-bind="comp.props"
    v-dynamic-events="comp.events || []"
  >
    {{ comp.content ? comp.content : "" }}
    <template v-for="(child, ind) in comp.children">
      <modal-view :key="ind" :comp="child" :slot="child.slot"></modal-view>
    </template>
  </component>
</template>

<script>
import RecurringCycle from "./RecurringCycle.vue";
import GeneralDropdowns from "./GeneralDropdowns.vue";
export default {
  name: "modal-view",
  components: {
    RecurringCycle,
    GeneralDropdowns,
  },
  directives: {
    DynamicEvents: {
      bind: function (el, binding, vnode) {
        const allEvents = binding.value;

        Object.keys(allEvents).forEach((event) => {
          // register handler in the dynamic component
          vnode.componentInstance.$on(event, (eventData) => {
            // when the event is fired, the proxyEvent function is going to be called
            vnode.context.proxyEvent(event, allEvents[event], eventData);
          });
        });
      },
      unbind: function (el, binding, vnode) {
        vnode.componentInstance.$off();
      },
    },
  },
  props: ["comp"],
  mounted() {},
  methods: {
    proxyEvent(event, callback, eventData) {
      console.log(event, eventData, callback);
      this[callback].apply({}, [eventData]);
    },
    hello(e) {
      console.log(e);
    },
  },
};
</script>
