<template>
<div class="">
  <div class="wpcm-recurring-btns">
    <ul class="d-inline-flex" v-if="show_recurring">
      <li
        class="m-0"
        v-if="strings"
        @click.prevent="setValue('recurring', true)"
      >
        <a href="#" :class="recurring && 'active'">{{
          strings.recurring
        }}</a>
      </li>
      <li
        class="m-0"
        v-if="strings"
        @click.prevent="setValue('recurring', false)"
      >
        <a href="#" :class="!recurring && 'active'">{{
          strings.one_time
        }}</a>
      </li>
    </ul>
  </div>
  <div class="wpcm-payment-gateways mt-3">
    <div class="mb-3">
      <ul class="wpcm-gateway-list" v-if="gateways">
        <li
          v-for="(gateway, gateway_id) in gateways"
          :key="gateway_id"
        >
          <a
            @click.prevent="setValue('payment_method', gateway.id)"
            :class="[(gateway.id === payment_method) && 'active']"
            title=""
            href="#"
            >
            <span v-if="gateway.icon"><img :src="gateway.icon" alt=""></span>
            {{
              gateway.title ? gateway.title : gateway.name
            }}</a
          >
        </li>
      </ul>
    </div>

    <slot name="gateway_data"></slot>
    <slot name="billing_info"></slot>
    <slot></slot>
  </div>
</div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
	props: ["gateways", "show_recurring", "strings"],
  computed: {
    ...mapState(['payment_method', 'recurring'])
  },
  methods: {
    setValue(key, val) {
      this.$store.commit('setValue', {key, val})
    },
    ...mapMutations(['back']),
    getBack() {
      this.$store.commit('setValue', {key, val})
    }
  }
};
</script>
