<template>
  <div :class="config.template_wrapper_class" v-if="config.steps">
    <div class="wpcm-row m-0">
      <slot name="left_sidebar"></slot>
      <div class="wpcm-col col-content">
        <slot name="global_top"></slot>
        <template v-for="(step) in config.steps">
          <div v-if="step == currentStep" :key="step + randKey">
            <div class="wpcm-row">
              <slot :name="`step_${step}_top`"></slot>
              <slot :name="`step_${step}_middle`"></slot>
              <slot :name="`step_${step}_bottom`"></slot>
            </div>
          </div>
        </template>
        <slot name="global_bottom"></slot>
      </div>
      <slot name="right_sidebar"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-template',
  props: ['config', 'currentStep'],
  computed: {
    randKey() {
      return (Math.random() + 1).toString(36).substring(7);
    }
  }
}
</script>