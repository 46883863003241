<template>
	<div class="">
		<div class="donation-payment-cycle" v-if="show_recurring && strings">
			<a
				href="#"
				:class="recurring && 'active'"
				@click.prevent="setValue('recurring', true)"
				>{{ strings.recurring }}</a
			>

			<a
				href="#"
				:class="!recurring && 'active'"
				@click.prevent="setValue('recurring', false)"
				>{{ strings.one_time }}</a
			>
		</div>
		<div class="position-relative pt-3">
			<div class="donation-payment-method">
				<div
					v-for="(gateway, gateway_id) in gateways"
					:key="gateway_id"
				>
					<a
						@click.prevent="setValue('payment_method', gateway.id)"
						:class="[
							'wpdonation-button',
							gateway.id === payment_method && 'active'
						]"
						title=""
						href="#"
						>{{ gateway.title ? gateway.title : gateway.name }}</a
					>
				</div>
			</div>
			<slot name="gateway_data"></slot>
		</div>
		<slot></slot>
	</div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
	props: ["gateways", "show_recurring", "strings"],
	computed: {
		...mapState(["payment_method", "recurring"])
	},
	methods: {
		setValue(key, val) {
			this.$store.commit("setValue", { key, val });
		},
		...mapMutations(["back"]),
		getBack() {
			this.$store.commit("setValue", { key, val });
		}
	}
};
</script>
