<template>
  <div>
    <h5 class="wpcm-top-title" v-if="top_title">{{ top_title }}</h5>
    <h3 class="wpcm-popup-title" v-if="title">{{ title }}</h3>
    <p class="wpcm-popup-tagline" v-if="tagline">{{ tagline }}</p>
  </div>
</template>

<script>
export default {
  props: ['top_title', 'title', 'tagline']
}
</script>