var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wpcm-wrapper" }, [
    _c(
      "div",
      {
        class: "donation-style-" + _vm.dstyle + " " + _vm.config.wrapper_class
      },
      [
        _c("div", { staticClass: "el-dialog__header" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "dialog-content"
          },
          [
            _vm.components
              ? _c(
                  "modal-template",
                  {
                    staticClass: "p-0",
                    attrs: { config: _vm.config, currentStep: _vm.step }
                  },
                  [
                    _vm._l(_vm.components, function(comp, index) {
                      return [
                        _c("modal-view", {
                          key: index,
                          attrs: { slot: comp.slot, comp: comp },
                          slot: comp.slot
                        })
                      ]
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }