<template>
  <div class="">
    <div class="wpcm-row wpcm-justify-content-center">
      <div class="wpcm-col-sm-12">
        <slot name="donation_dropdowns"></slot>
        <div
          class="el-custom-select mt-5"
          v-if="show_currency_dropdown && currencies"
        >
          <el-select
            class="w-100"
            :value="currency"
            @change="setValue('currency', $event)"
            placeholder="Select Currency"
          >
            <el-option
              :value="key"
              :key="key"
              :label="curr"
              v-for="(curr, key) in currencies"
              >{{ curr }}</el-option
            >
          </el-select>
        </div>
        <div class="donation-amount-box my-5">
          <h4>How much would you like to donate?</h4>
          <div class="custom-donation-amount wpcm-d-inline-block" v-if="custom_amount">
            <span>{{ getSymbol() }}</span> 
            <input type="text" :value="amount" @input="setAmount">
          </div>
          <div class="donation-amount-list">
            <ul class="list-unstyled" v-if="show_amounts && amounts">
              <li
                v-for="amnt in amounts"
                :key="amnt"
                @click.prevent="setValue('amount', amnt)"
              >
                <a
                  href="#"
                  :class="[
                    'wpdonation-button',
                    amount == amnt && 'active'
                  ]"
                >
                  {{ getSymbol() + ' ' + amnt }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
	props: [
		"currencies",
    "symbols",
    "symbol",
		"amounts",
		"strings",
		"show_currency_dropdown",
		"show_amounts",
		"custom_amount",
		"show_recurring"
	],
	data() {
		return {
			// currency: 'usd'
		};
	},
	computed: {
		...mapState(["amount", "currency", "recurring"])
	},
	mounted() {},
	methods: {
		...mapMutations(["setAmount"]),
		setValue(key, val) {
      this.$store.commit('setValue', {key, val})
    },
    getSymbol() {
      return (this.symbols[this.currency] != undefined) ? this.symbols[this.currency] : this.symbol
    }
	}
};
</script>
