import { render, staticRenderFns } from "./ModalView.vue?vue&type=template&id=364006d6&"
import script from "./ModalView.vue?vue&type=script&lang=js&"
export * from "./ModalView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\xampp\\htdocs\\lifeline-donation-pro\\wp-content\\plugins\\lifeline-donation-pro\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('364006d6')) {
      api.createRecord('364006d6', component.options)
    } else {
      api.reload('364006d6', component.options)
    }
    module.hot.accept("./ModalView.vue?vue&type=template&id=364006d6&", function () {
      api.rerender('364006d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/ModalView.vue"
export default component.exports