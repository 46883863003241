var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.step < _vm.config.steps
    ? _c(
        "el-button",
        {
          on: {
            click: function($event) {
              return _vm.$store.commit("next")
            }
          }
        },
        [_vm._v("\n  " + _vm._s(_vm.text || "Proceed") + " \n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }