const { __, setLocaleData } = wp.i18n
const Vuex = window.Vuex;
import Modal from './components/Modal.vue';
import PageTemplate from './components/PageTemplate.vue';
import Button from './components/Button.vue';
import AmountBox from './components/AmountBox.vue';
import ProceedBtn from './components/ProceedBtn.vue';
import NextBtn from './components/NextBtn.vue';
import PrevBtn from './components/PrevBtn.vue';
import Heading from './components/Heading.vue';
import TitleDesc from './components/TitleDesc.vue';
import DonationInfo from './components/DonationInfo.vue';
import DonationInfo2 from './components/style-two/DonationInfo.vue';
import DonationInfo3 from './components/style-three/DonationInfo.vue';
import DonationForm from './components/DonationForm.vue';
import DonationForm2 from './components/style-two/DonationForm.vue';
import DonationForm3 from './components/style-three/DonationForm.vue';
import PaymentGateways from './components/Gateways.vue';
import PaymentGateways2 from './components/style-two/Gateways.vue';
import PaymentGateways3 from './components/style-three/Gateways.vue';
import BillingInfo from './components/BillingInfo.vue';
import BillingInfo2 from './components/style-two/BillingInfo.vue';

import store from './store'
import donation_mixin from './mixin'

ELEMENT.locale(ELEMENT.lang.en)
Vue.use(ELEMENT)


Vue.component('lifeline-donation-button', Button);
Vue.component('lifeline-donation-modal', Modal);
Vue.component('lifeline-donation-page-template', PageTemplate);

// Components
Vue.component('lifeline-donation-heading', Heading);
Vue.component('lifeline-donation-box-title', TitleDesc);
Vue.component('lifeline-donation-amount-box', AmountBox);
Vue.component('lifeline-donation-predefined-amounts', require('./components/PreDefinedAmounts.vue').default);
Vue.component('lifeline-donation-proceed-btn', ProceedBtn);
Vue.component('lifeline-donation-next-btn', NextBtn);
Vue.component('lifeline-donation-back-btn', PrevBtn);
Vue.component('lifeline-donation-info', DonationInfo);
Vue.component('lifeline-donation-info2', DonationInfo2);
Vue.component('lifeline-donation-info3', DonationInfo3);
Vue.component('lifeline-donation-form', DonationForm);
Vue.component('lifeline-donation-form2', DonationForm2);
Vue.component('lifeline-donation-form3', DonationForm3);
Vue.component('lifeline-donation-gateways', PaymentGateways);
Vue.component('lifeline-donation-gateways2', PaymentGateways2);
Vue.component('lifeline-donation-gateways3', PaymentGateways3);
Vue.component('lifeline-donation-billing-form', BillingInfo);
Vue.component('lifeline-donation-billing-form2', BillingInfo2);

lifeline_donation.eventBus = new Vue()


const elem = document.querySelectorAll(".lifeline-donation-app");
if(elem) {
  elem.forEach(element => {
    const app = new Vue({
      el: element,
      store,
    });
  });
}

jQuery(document).trigger('webinane-commerce-checkout-form-loaded')



