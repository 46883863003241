<template>
  <component :is="tag">{{text}}</component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'h3'
    },
    text: String
  }
}
</script>