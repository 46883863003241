<template>
	<div class="d-inline-block">
		<span @click.prevent="showModal = true">
			<slot></slot>
		</span>
		<lifeline-donation-modal
			v-if="showModal"
			@close="showModal = false"
			:showModal="showModal"
			:id="id"
			:dstyle="dstyle"
		></lifeline-donation-modal>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		id: Number,
		dstyle: [Number, String]
	},
	data() {
		return {
			showModal: false
		};
	},
	methods: {
		viewModal() {
			// lifeline_donation.eventBus.$emit('loadModal', this)
		},
		close(app) {
			this.showModal = false;
			jQuery("html").removeClass("modalOpen");
		}
	}
};
</script>
