var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config.steps
    ? _c("div", { class: _vm.config.template_wrapper_class }, [
        _c(
          "div",
          { staticClass: "wpcm-row m-0" },
          [
            _vm._t("left_sidebar"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "wpcm-col col-content" },
              [
                _vm._t("global_top"),
                _vm._v(" "),
                _vm._l(_vm.config.steps, function(step) {
                  return [
                    step == _vm.currentStep
                      ? _c("div", { key: step + _vm.randKey }, [
                          _c(
                            "div",
                            { staticClass: "wpcm-row" },
                            [
                              _vm._t("step_" + step + "_top"),
                              _vm._v(" "),
                              _vm._t("step_" + step + "_middle"),
                              _vm._v(" "),
                              _vm._t("step_" + step + "_bottom")
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ]
                }),
                _vm._v(" "),
                _vm._t("global_bottom")
              ],
              2
            ),
            _vm._v(" "),
            _vm._t("right_sidebar")
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }