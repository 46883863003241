<template>
  <div class="step2">
    <div class="donar-info">
      <h4>Personal Info</h4>
      <form>
        <input
          type="text"
          :placeholder="trans('first_name')"
          :value="billing_fields.first_name"
          @input="setBillingValue('first_name', $event.target.value)"
          :disabled="loading"
          required
        />
        <input
          type="text"
          :placeholder="trans('last_name')"
          :value="billing_fields.last_name"
          @input="setBillingValue('last_name', $event.target.value)"
          :disabled="loading"
          required
        />
        <input
          type="email"
          :placeholder="trans('email')"
          :value="billing_fields.email"
          :readonly="is_logged_in && email"
          :disabled="loading"
          @input="setBillingValue('email', $event.target.value)"
          required
        />
        <input
          type="tel"
          :placeholder="trans('phone')"
          :value="billing_fields.phone"
          @input="setBillingValue('phone', $event.target.value)"
          :disabled="loading"
          onkeyup="this.value=this.value.replace(/[^\d]/,'')"
        />
        <input
          type="text"
          :placeholder="trans('address')"
          :value="billing_fields.address"
          @input="setBillingValue('address', $event.target.value)"
          :disabled="loading"
        />
      </form>
    </div>
  </div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
  props: ['is_logged_in', 'email', 'strings'],
  computed: {
    ...mapState(['billing_fields', 'loading'])
  },
  watch: {
    email(val) {
      if(this.is_logged_in && val) {
        this.setBillingValue('email', val)
      }
    }
  },
  mounted() {
    if(this.is_logged_in && this.email) {
      this.setBillingValue('email', this.email)
    }
  },
  methods: {
    setValue(key, val) {
      this.$store.commit('setValue', {key, val})
    },
    setBillingValue(key, val) {
      this.$store.commit('setBillingValue', {key, val})
    },
    trans(key) {
      if(this.strings) {
        return this.strings[key]
      }
    }
  }
}
</script>