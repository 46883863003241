<template>
  <div class="el-custom-select">
    <el-select v-model="value" v-if="recurring" class="w-100" placeholder="Select Recurring Cycle">
      <el-option
        v-for="(opt, key) in options"
        :key="key"
        :value="key"
        :label="opt"
      >
        {{ opt }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
const { mapState } = window.Vuex
export default {
  name: 'recurring-cycle',
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          weekly: 'Weekly',
          monthly: 'Monthly',
          quarterly: 'Quarterly',
          yearly: 'Yearly',
        }
      }
    }
  },
  computed: {
    ...mapState(['recurring']),
    value: {
      get() {
        return this.$store.state.cycle
      },
      set(val) {
        this.$store.commit('setValue', {key: 'cycle', val})
      }
    }
  }
}
</script>