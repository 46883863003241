<template>
	<div class="donation-box-title">
		<span
			><img
				:src="img"
				alt="new-img.png"
		/></span>
		<div>
			<h2 class="mb-0">{{ title }}</h2>
			<p class="mb-0" v-if="tagline">{{ tagline }}</p>
		</div>
	</div>
</template>

<script>
export default {
  props: ['title', 'tagline', 'img']
};
</script>
