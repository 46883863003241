<template>
	<div class="wpcm-amount-box">	
		<div class="wpcm-donation-amt-fields">
			<div
				class="el-custom-select"
				v-if="show_currency_dropdown && currencies"
			>
				<el-select
					class="w-100"
					:value="currency"
					@change="setValue('currency', $event)"
				>
					<el-option
						:value="key"
						:key="key"
						:label="curr"
						v-for="(curr, key) in currencies"
						>{{ curr }}</el-option
					>
				</el-select>
			</div>
      <slot name="donation_dropdowns"></slot>
			<div class="" v-if="strings">
				<strong class="wpcm-cstm-amt-txt">{{
					strings.how_much
				}}</strong>
			</div>
      <ul class="wpcm-pre-dfind-amt" v-if="show_amounts && amounts">
        <li
          v-for="amnt in amounts"
          :key="amnt"
          @click.prevent="setValue('amount', amnt)"
        >
          <a
            href="#"
            :class="[
              'wpdonation-button',
              amount == amnt && 'active'
            ]"
          >
            {{ getSymbol() }}{{ amnt }}</a
          >
        </li>
      </ul>
      
    </div>
	</div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;

export default {
  components: {},
  props: [
    "currencies",
    "amounts",
    "strings",
    "show_currency_dropdown",
    "show_amounts",
    "custom_amount",
    "show_recurring",
    "symbols",
    "symbol"
  ],
  data() {
    return {
      // currency: 'usd'
    };
  },
  computed: {
    ...mapState(["amount", "currency", "recurring"]),
  },
  mounted() {},
  methods: {
    ...mapMutations(["setAmount"]),
    setValue(key, val) {
      this.$store.commit("setValue", { key, val });
    },
    getSymbol() {
      return (this.symbols[this.currency] != undefined) ? this.symbols[this.currency] : this.symbol
    }
  },
};
</script>
