var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.show_recurring && _vm.strings
        ? _c("div", { staticClass: "donation-payment-cycle" }, [
            _c(
              "a",
              {
                class: _vm.recurring && "active",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.setValue("recurring", true)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.strings.recurring))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                class: !_vm.recurring && "active",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.setValue("recurring", false)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.strings.one_time))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "position-relative pt-3" },
        [
          _c(
            "div",
            { staticClass: "donation-payment-method" },
            _vm._l(_vm.gateways, function(gateway, gateway_id) {
              return _c("div", { key: gateway_id }, [
                _c(
                  "a",
                  {
                    class: [
                      "wpdonation-button",
                      gateway.id === _vm.payment_method && "active"
                    ],
                    attrs: { title: "", href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setValue("payment_method", gateway.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(gateway.title ? gateway.title : gateway.name))]
                )
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm._t("gateway_data")
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }