var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "wpcm-custm-amt-before-title" },
        [
          _vm._t("before_title"),
          _vm._v(" "),
          _vm.title
            ? _c("h3", { staticClass: "wpcm-custm-amt-title" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wpcm-custom-amt-box-container" },
        [
          _vm._t("before_box"),
          _vm._v(" "),
          _vm.custom_amount
            ? _c(
                "div",
                { staticClass: "wpcm-custm-amt-box" },
                [
                  _vm.symbol
                    ? _c("span", { staticClass: "wpcm-symbl-prefix" }, [
                        _vm._v(_vm._s(_vm.getSymbol()))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      placeholder: _vm.strings
                        ? _vm.strings.donation_amount
                        : "Enter The Amount You Want"
                    },
                    domProps: { value: _vm.amount },
                    on: {
                      input: _vm.setAmount,
                      keypress: function($event) {
                        return _vm.isNumber($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm._t("in_box")
                ],
                2
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }