<template>
<div class="wpcm-wrapper">
  <div :class="`donation-style-${dstyle} ${config.wrapper_class}`">
    <div class="el-dialog__header"></div>
    <div class="dialog-content" v-loading="loading">
      <modal-template v-if="components" :config="config" :currentStep="step" class="p-0">
        <template v-for="(comp, index) in components">
          <modal-view :key="index" :comp="comp" :slot="comp.slot"></modal-view>
        </template>
      </modal-template>
    </div>
  </div>
</div>
</template>

<script>
const $ = jQuery;
const { mapState, mapMutations } = window.Vuex;
import descriptor from '../utils/rules'
import ModalView from "./ModalView.vue";
import ModalTemplate from "./ModalTemplate.vue";

export default {
  name: "Modal",
  components: { ModalView, ModalTemplate },
  props: ["id", "dstyle"],
  data() {
    return {
      loader: "",
    };
  },
  computed: {
    ...mapState(["loading", "components", "config", "step"]),
    showModal: {
      get() {
        return this.$store.state.showModal;
      },
      set(val) {
        this.$store.commit("setValue", { key: "showModal", val });
      },
    },
  },
  watch: {
    loading(val) {
      if (!val && this.loader) {
        this.loader.close();
      }
    },
  },
  mounted() {
    this.$store.dispatch("getData", { vm: this });
    this.loader = this.$loading({
      lock: true,
    });
  },
  methods: {
    ...mapMutations(["next", "back", "reset"]),
    submit() {
      const { state } = this.$store;
      this.$store
        .dispatch("validate", { rules: descriptor, fields: state })
        .then((res) => {
          this.$store.dispatch('submit', {vm: this})
        })
        .catch((fields, errors) => {
          this.validation_catch(fields, errors);
        });
      // this.$store.dispatch("submit", { vm: this });
    },
    validation_catch(fields, errors) {
      _.each(fields, (field) => {
        setTimeout(() => {
          this.$notify.error({
            title: "Error",
            message: field[0].message,
            offset: 40,
          });
        }, 300);
      });
    },
  },
};
</script>
