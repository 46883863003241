export default {
	loading: false,
	step: 1,
	payment_method: '',
	recurring: false,
	
	billing_period: 'Month',
	amount: null,
	// post_id: 0,
	personal: {
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		address: '',
	},
	billing_fields: {
		first_name: '',
		last_name: '',
		company: '',
		base_country: '',
		address_line_1: '',
		address_line_2: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		email: ''
	},
	shipping_fields: {
		first_name: '',
		last_name: '',
		company: '',
		base_country: '',
		address_line_1: '',
		address_line_2: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
	},
	ccard: {
		exp_month: '',
		exp_year: '',
		number: '',
		code: ''
	},
	gateways: {},
	currencies: {},
	currency: 'USD',
	symbol: '$',
	amount_slabs: [],
	collected_amt: 0,
	needed_amt: 0,
	error_message: '',
	showModalBox: false,
	showModalBox2: false,
	validated: false,
	extras: {
		dropdown: '',
		recuring_start:'',
		recuring_ending:'cancel',
		ending_date:'',
		gifts_number:'',
		donation_purpose: 'all_projects_causes'
	},
	text: '',
	title: '',
	dropdown: []
}