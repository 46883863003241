var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "donation-box-title" }, [
    _c("span", [_c("img", { attrs: { src: _vm.img, alt: "new-img.png" } })]),
    _vm._v(" "),
    _c("div", [
      _c("h2", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm.tagline
        ? _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.tagline))])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }