<template>
  <div class="wpdonation-box">
    <h2 class="wpdonation-title" v-if="strings">{{ strings.title }}</h2>

    <div class="easy-donation-box">
      <div class="single-credit-cardd">
        <div class="wpcm-row wpmc-justify-content-center">
          <div class="wpcm-col-md-6">
            <div class="textfield">
              <input
                type="text"
                :placeholder="trans('first_name')"
                :value="billing_fields.first_name"
                @input="setBillingValue('first_name', $event.target.value)"
                :disabled="loading"
                required
              />
            </div>
          </div>
          <div class="wpcm-col-md-6">
            <div class="textfield">
              <input
                type="text"
                :placeholder="trans('last_name')"
                :value="billing_fields.last_name"
                @input="setBillingValue('last_name', $event.target.value)"
                :disabled="loading"
                required
              />
            </div>
          </div>
          <div class="wpcm-col-md-12">
            <div class="textfield">
              <input
                type="email"
                :placeholder="trans('email')"
                :value="billing_fields.email"
                :readonly="is_logged_in && email"
                :disabled="loading"
                @input="setBillingValue('email', $event.target.value)"
                required
              />
            </div>
          </div>
          <div class="wpcm-col-md-12">
            <div class="textfield">
              <input
                type="text"
                :placeholder="trans('address')"
                @input="setBillingValue('address', $event.target.value)"
                :disabled="loading"
                :value="billing_fields.address"
              />
            </div>
          </div>
          <div v-if="show_country" class="wpcm-col-md-12">
            <div class="textfield">
              <el-select
                filterable
                :placeholder="trans('country')"
                :loading="select_loading"
                @change="setBillingValue('base_country', $event)"
                v-model="country"
              >
                <el-option
                  v-for="(name, key) in countries"
                  :key="key"
                  :label="name"
                  :value="key">
                </el-option>
              </el-select>
              
            </div>
          </div>
          <div v-if="show_county" class="wpcm-col-md-12">
            <div class="textfield">
              <input
                type="text"
                :placeholder="trans('county')"
                @input="setBillingValue('state', $event.target.value)"
                :disabled="loading"
                :value="billing_fields.state"
              />
            </div>
          </div>
          <div v-if="show_city" class="wpcm-col-md-12">
            <div class="textfield">
              <input
                type="text"
                :placeholder="trans('city')"
                @input="setBillingValue('city', $event.target.value)"
                :disabled="loading"
                :value="billing_fields.city"
              />
            </div>
          </div>
          <div v-if="show_postal" class="wpcm-col-md-12">
            <div class="textfield">
              <input
                type="text"
                :placeholder="trans('postal_code')"
                @input="setBillingValue('zip', $event.target.value)"
                :disabled="loading"
                :value="billing_fields.zip"
              />
            </div>
          </div>
          <div v-if="show_tax" class="wpcm-col-md-12">
            <div class="textfield">
              <input
                type="text"
                :placeholder="trans('tax_code')"
                @input="setBillingValue('tax_code', $event.target.value)"
                :disabled="loading"
                :value="billing_fields.tax_code"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;
export default {
  props: ["is_logged_in", "email", 'show_country', 'show_county', 'show_city', 'show_postal', 'show_tax', "strings"],
  computed: {
    ...mapState(["billing_fields", "loading"]),
  },
  data() {
    return {
      countries: [],
      select_loading: false,
      country: '',
    }
  },
  watch: {
    email(val) {
      if (this.is_logged_in && val) {
        this.setBillingValue("email", val);
      }
    },
  },
  mounted() {
    if (this.is_logged_in && this.email) {
      this.setBillingValue("email", this.email);
    }
    this.getCountries();
  },
  methods: {
    setValue(key, val) {
      this.$store.commit("setValue", { key, val });
    },
    setBillingValue(key, val) {
      this.$store.commit("setBillingValue", { key, val });
    },
    trans(key) {
      if (this.strings) {
        return this.strings[key];
      }
    },
    getCountries() {
      this.select_loading = true;

      const $ = jQuery;

      $.ajax({
        url: window.lifeline_donation.homeurl+ '/wp-json/webinane-commerce/v1/countries',
        success: res => {
          this.countries = res.countries
        },
        complete: res => {
          this.select_loading = false
        }
      })
    }
  },
};
</script>