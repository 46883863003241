const { __, setLocaleData } = wp.i18n

import data from './utils/data'

export default {
	data() {
		data.document = document
		return data
	},
	mounted() {
		this.$on('webinane-checkout-form-validation', (val) => {
			this.validated = val
		})
		const mytest = {
			template: '<div><slot name="test"></slot></div>'
		}

		var ComponentClass = Vue.extend(mytest)
		var instance = new ComponentClass({
		    propsData: { type: 'primary' }
		})
		instance.$slots.test = [ '<span>Click me!</span>' ]
		instance.$mount() // pass nothing
		if(this.$refs.container) {
			this.$refs.container.appendChild(instance.$el)
		}
	},
	watch:{
		/* step: function(old, newval) {
			if( jQuery.fn.select2 == 'function' ) {
				jQuery('select').select2()
			}
		},
		loading: function(newval, old) {
			if( newval === true ){
				jQuery(document).trigger('webinane-donation-popup-open', this);
				jQuery('.donation-modal-wraper,.donation-modal-preloader').show();
			} else {
				jQuery(document).trigger('webinane-donation-popup-close', this);
				jQuery('.donation-modal-wraper,.donation-modal-preloader').hide();
			}
		} */
	},
	methods: {
		jQuery(val) {
			return jQuery(val)
		},
		is_recurring() {
			if(this.gateways[this.payment_method] !== undefined) {
				let gateway = this.gateways[this.payment_method]
				if(gateway.recurring) {
					return true
				}
			}
			this.recurring = false
			return false
		},
		showCurrencyAmount() {
			this.step = 1
		},
		getwayActiveClass(gateway) {
			return (gateway == this.payment_method) ? 'wpdonation-button active' : 'wpdonation-button'
		},
		currencyStep() {
			if( this.amount < 1 ) {
				alert(__('Please enter or choose amount', 'lifeline-donation'));
				return;
			}
			this.step = 2
		},
		getYears() {
			let d = new Date();
			let year = parseInt(d.getFullYear())

			return _.range(year, (year+10))
		},
		submit() {
			this.$emit('webinane-checkout-form-validation', this.validate());
			
			if( ! this.validated ) {
				return;
			}
			let thisis = this
			let $ = jQuery
			let type = (this.post_id) ? 'single' : 'general'
			thisis.error_message = '';

			this.loading = true

			$.ajax({
				url: wpcm_data.ajaxurl,
				type: 'post',
				data: {
					action: wpcm_data.ajax_action, 
					nonce: wpcm_data.nonce, 
					callback: ['Lifeline_Donation', 'donate_now'], 
					post_id: thisis.post_id,
					currency: thisis.currency,
					amount: thisis.amount,
					gateway: thisis.payment_method,
					recurring: thisis.recurring,
					billing_period: thisis.billing_period,
					info: thisis.billing_fields,
					cc: thisis.ccard,
					extras: thisis.extras,
					type
				},
				success: (res) => {
					if(res.success !== undefined ) {
						if( res.success == false ) {
							thisis.$notify.error({
								message: res.data.message,
								offset: 30 
							})
						}
					}
					if( res.type !== undefined ) {
					
						if( res.type == 'redirect') {
							window.location = res.url
						} 

					} else {
						$(document).trigger('webinane_commerce_checkout_form_submitted', res)
					}
				},
				complete: (res) => {
					thisis.loading = false
					if( res.status !== 200 ) {
						this.$notify.error({
							message: res.statusText,
							offset: 30
						})
					}
				}
			});
		},
		validate() {
			let error_found = false;

			let validation = {
				payment_method: __('Please select payment method', 'lifeline-donation'),
				amount: __('Please enter the amount to donate', 'lifeline-donation'), 
				currency: __('Choose the currency to donate', 'lifeline-donation'),
			}
			_.each(validation, (msg, field) => {
				if( ! this[field] && !error_found ) {

					this.$notify.error({
						title: __('Error', 'lifeline-donation'),
			          	message: msg,
			          	offset: 30,
			        });
					error_found = true
				}
			})
			let personal = {
				first_name: __('Please enter first name', 'lifeline-donation' ),
				last_name: __('Please enter last name', 'lifeline-donation' ),
				email: __('Please enter valid email address', 'lifeline-donation'),
				// phone: __('Please enter your phone number', 'lifeline-donation'),
				// address: __('Please enter your address', 'lifeline-donation' ),
			}
			_.each(personal, (msg, field) => {
				if( ! this.billing_fields[field] && ! error_found ) {

					this.$notify.error({
						title: __('Error', 'lifeline-donation'),
			          	message: msg,
			          	offset: 30,
			        });
					error_found = true
				}
			})

			return (! error_found) ? true : false;
		},
		closePopup(modal) {
			document.querySelector('html').classList.remove('modalOpen')
			if( modal == '2') {
				this.showModalBox2 = false
			} else {
				this.showModalBox = false
			}
			jQuery(document).trigger('webinane_donation_modal_closed', this);
		},
		getCurrencySymbol(e) {
			let value = this.currency
			let $ = jQuery

			$.ajax({
				url: wpcm_data.ajaxurl,
				type: 'post',
				data: {
					action: wpcm_data.ajax_action, 
					nonce: wpcm_data.nonce, 
					callback: ['Lifeline_Donation', 'currency_symbol'], 
					currency: value,
				},
				success: (res) => {
					if(res.success !== undefined ) {
						if( res.success == false ) {
							this.$notify.error({
								message: res.data.message,
								offset: 30 
							})
						} else {
							this.symbol = res.data
						}
					}
				},
				complete: (res) => {
					this.loading = false
					if( res.status !== 200 ) {
						this.$notify.error({
							message: res.statusText,
							offset: 30
						})
					}
				}
			});
		},
		size(value) {
			return _.size(value)
		}
	}
}