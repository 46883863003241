var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.comp
    ? _c(
        _vm.comp.is,
        _vm._b(
          {
            directives: [
              {
                name: "dynamic-events",
                rawName: "v-dynamic-events",
                value: _vm.comp.events || [],
                expression: "comp.events || []"
              }
            ],
            tag: "component"
          },
          "component",
          _vm.comp.props,
          false
        ),
        [
          _vm._v(
            "\n  " + _vm._s(_vm.comp.content ? _vm.comp.content : "") + "\n  "
          ),
          _vm._l(_vm.comp.children, function(child, ind) {
            return [
              _c("modal-view", {
                key: ind,
                attrs: { slot: child.slot, comp: child },
                slot: child.slot
              })
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }