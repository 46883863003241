<template>
	<div class="wpcm-amount-box">	
		<div class="wpcm-donation-amt-fields">
      <div
        class="el-custom-select"
        v-if="enable_custom_dropdown && donation_custom_dropdown"
      >
        <el-select
          class="w-100 mb-3"
          :value="custom_dropdown_def_val"
          @change="setExtras('donation_custom_dropdown', donation_custom_dropdown[$event])"
        >
          <el-option
            :value="key"
            :key="key"
            :label="dropdown"
            v-for="(dropdown, key) in donation_custom_dropdown"
            >{{ dropdown }}</el-option
          >
        </el-select>
      </div>
			<div
				class="el-custom-select"
				v-if="show_currency_dropdown && currencies"
			>
				<el-select
					class="w-100"
					:value="currency"
					@change="setValue('currency', $event)"
				>
					<el-option
						:value="key"
						:key="key"
						:label="curr"
						v-for="(curr, key) in currencies"
						>{{ curr }}</el-option
					>
				</el-select>
			</div>
      <div v-else>
      <input 
        type="hidden" 
        :value="currency"
        >
      </div>
      <slot name="donation_dropdowns"></slot>
			<div class="" v-if="strings && show_amounts && amounts">
				<strong class="wpcm-cstm-amt-txt">{{
					strings.how_much
				}}</strong>
			</div>
      <ul class="wpcm-pre-dfind-amt" v-if="show_amounts && amounts">
        <li
          v-for="amnt in amounts"
          :key="amnt"
          @click.prevent="setValue('amount', convert(amnt))"
        >
          <a
            href="#"
            :class="[
              'wpdonation-button',
              amount == convert(amnt) && 'active'
            ]"
          >
            {{ formatPrice(amnt) }}</a
          >
        </li>
      </ul>
      
    </div>
	</div>
</template>

<script>
const { mapState, mapMutations } = window.Vuex;

export default {
  components: {},
  props: [
    "currencies",
    "base_currency",
    "amounts",
    "strings",
    "show_currency_dropdown",
    "show_amounts",
    "custom_amount",
    "show_recurring",
    "symbols",
    "symbol",
    "format_price",
    "enable_custom_dropdown",
    "donation_custom_dropdown"
  ],
  data() {
    return {
      //currency: 'USD'
      custom_dropdown_def_val: ''
    };
  },
  computed: {
    ...mapState(["amount", "currency", "recurring", "extras"]),
  },
  mounted() {
    if(this.enable_custom_dropdown && _.size(this.donation_custom_dropdown)) { 
      this.custom_dropdown_def_val = this.donation_custom_dropdown[0];
      this.setExtras('donation_custom_dropdown', this.donation_custom_dropdown[0])
    }
    if(this.show_amounts && _.size(this.amounts)) {
      this.setValue('amount', this.amounts[0])
    }
    if(this.show_currency_dropdown && _.size(this.currencies)) {
      this.setValue('currency', _.keys(this.currencies)[0])
    } else {
      this.setValue('currency', this.base_currency)
    }
  },
  methods: {
    ...mapMutations(["setAmount"]),
    setExtras(key, val){
      this.$store.commit("setExtras", { key, val });
      this.custom_dropdown_def_val = this.extras.donation_custom_dropdown;
    },
    setValue(key, val) {
      this.$store.commit("setValue", { key, val });
    },
    getSymbol() {
      return (this.symbols[this.currency] != undefined) ? this.symbols[this.currency] : this.symbol
    },
    convert(amt) {
      const { exchange_rates } = window.lifeline_donation
      const syb = this.currency

      if(_.get(exchange_rates, syb)) {
        return parseFloat(_.get(exchange_rates, syb) * amt).toFixed(2);
      }

      return amt
    },
    formatPrice(price) {
      let settings = this.format_price;
      let sym = this.getSymbol();
      let d_point = settings.d_point;
      let d_sep = settings.d_sep;
      let position = settings.position;
      let sep = settings.sep;

      price = this.formatMoney(price, d_point, d_sep, sep);
      if (position == "left") {
        price = sym + price;
      } else if (position == "right") {
        price = price + sym;
      } else if (position == "left_s") {
        price = sym + " " + price;
      } else if (position == "right_s") {
        price = price + " " + sym;
      } else {
        price = sym + " " + price;
      }
      return price;
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {
        this.$notify({ type: "error", message: e });
      }
    }
  },
};
</script>
